"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20240910113741/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$SYSTEM_MESSA, _exports$NOTIFICATION;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

var order_1 = require("../order");

exports.SYSTEM_MESSAGE_FOR_STATUS = (_exports$SYSTEM_MESSA = {}, _defineProperty(_exports$SYSTEM_MESSA, order_1.ORDER_ITEM_STATUSES.FILES_REFUSED, types_1.SYSTEM_MESSAGES.ORDER_ITEM_FILES_REFUSED), _defineProperty(_exports$SYSTEM_MESSA, order_1.ORDER_ITEM_STATUSES.VALIDATED_FOR_PRODUCTION, types_1.SYSTEM_MESSAGES.ORDER_ITEM_VALIDATED_FOR_PRODUCTION), _exports$SYSTEM_MESSA);
exports.NOTIFICATION_TYPE_FOR_SYSTEM_MESSAGE_TYPE = (_exports$NOTIFICATION = {}, _defineProperty(_exports$NOTIFICATION, types_1.SYSTEM_MESSAGES.ORDER_CONFIRMED, types_1.NOTIFICATION_TYPES.ORDER_CONFIRMED), _defineProperty(_exports$NOTIFICATION, types_1.SYSTEM_MESSAGES.ORDER_ITEM_FILES_REFUSED, types_1.NOTIFICATION_TYPES.FILES_REFUSED), _defineProperty(_exports$NOTIFICATION, types_1.SYSTEM_MESSAGES.ORDER_ITEM_VALIDATED_FOR_PRODUCTION, types_1.NOTIFICATION_TYPES.ORDER_ITEM_VALIDATED_FOR_PRODUCTION), _defineProperty(_exports$NOTIFICATION, types_1.SYSTEM_MESSAGES.ORDER_SHIPPED, types_1.NOTIFICATION_TYPES.ORDER_SHIPPED), _exports$NOTIFICATION);