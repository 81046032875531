"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20240910113741/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _toConsumableArray = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20240910113741/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Option = require("fp-ts/lib/Option");

var flatMap = require("array.prototype.flatmap");

var functional_1 = require("./functional");

var VALIDATION_ERROR_MESSAGES;

(function (VALIDATION_ERROR_MESSAGES) {
  // Par exemple, type string au lieu de number
  VALIDATION_ERROR_MESSAGES["INVALID_VALUE"] = "validation.invalid_value";
  VALIDATION_ERROR_MESSAGES["TOO_LOW"] = "validation.too_low";
  VALIDATION_ERROR_MESSAGES["TOO_HIGH"] = "validation.too_high";
  VALIDATION_ERROR_MESSAGES["REQUIRED"] = "validation.required"; // La valeur passée n'est pas membre d'un ensemble d'options

  VALIDATION_ERROR_MESSAGES["INVALID_OPTION"] = "validation.invalid_option"; // Par exemple quand la longueur d'un string est trop haute ou trop basse

  VALIDATION_ERROR_MESSAGES["INVALID_LENGTH"] = "validation.invalid_length";
  VALIDATION_ERROR_MESSAGES["MUST_BE_UNIQUE"] = "validation.must_be_unique";
  VALIDATION_ERROR_MESSAGES["TOO_LONG"] = "validation.too_long";
  VALIDATION_ERROR_MESSAGES["TOO_SHORT"] = "validation.too_short";
  VALIDATION_ERROR_MESSAGES["INVALID_EMAIL"] = "validation.invalid_email";
  VALIDATION_ERROR_MESSAGES["INVALID_PHONE_NUMBER"] = "validation.invalid_phone_number";
  VALIDATION_ERROR_MESSAGES["NOT_ALPHA_NUM"] = "validation.not_alpha_num";
  VALIDATION_ERROR_MESSAGES["NOT_POSITIVE"] = "validation.not_positive";
})(VALIDATION_ERROR_MESSAGES = exports.VALIDATION_ERROR_MESSAGES || (exports.VALIDATION_ERROR_MESSAGES = {}));

function validateInputFromValidationMap(input, validationMap) {
  return flatMap(Object.keys(validationMap).map(function (propertyName) {
    return validateProperty(propertyName, input[propertyName], validationMap[propertyName]);
  }), functional_1.identity);
}

exports.validateInputFromValidationMap = validateInputFromValidationMap;

var isValidationConstraintList = function isValidationConstraintList(map) {
  return Array.isArray(map);
};

function validateProperty(propertyName, value, definition) {
  if (isValidationConstraintList(definition)) {
    return definition.reduce(function (acc, currConstraint) {
      var error = currConstraint(value);

      if (Option.isSome(error)) {
        return [].concat(_toConsumableArray(acc), [_objectSpread({
          property: propertyName
        }, error.value)]);
      }

      return acc;
    }, []);
  }

  var subErrors = validateInputFromValidationMap(value, definition);

  if (subErrors.length > 0) {
    return [{
      property: propertyName,
      message: VALIDATION_ERROR_MESSAGES.INVALID_VALUE,
      subErrors: subErrors
    }];
  }

  return [];
}

var isNullable = function isNullable(value) {
  return value === null || value === undefined || value === '';
};

exports.isRequired = function (value) {
  return isNullable(value) ? Option.some({
    message: VALIDATION_ERROR_MESSAGES.REQUIRED
  }) : Option.none;
};

exports.isInteger = function (value) {
  var intValue = parseInt(value, 10);
  var floatValue = parseFloat(value);
  return !isNullable(value) && (typeof value !== 'number' || isNaN(intValue) || floatValue % intValue !== 0) ? Option.some({
    message: VALIDATION_ERROR_MESSAGES.INVALID_VALUE
  }) : Option.none;
};

exports.isFloat = function (value) {
  return !isNullable(value) && (typeof value !== 'number' || isNaN(value)) ? Option.some({
    message: VALIDATION_ERROR_MESSAGES.INVALID_VALUE
  }) : Option.none;
};

exports.isBoolean = function (value) {
  return !isNullable(value) && ![false, true].includes(value) ? Option.some({
    message: VALIDATION_ERROR_MESSAGES.INVALID_VALUE
  }) : Option.none;
}; // Ce validateur ignore une valeur falsy (ajouter REQUIRED si besoin)


exports.isInArray = function (validValues) {
  return function (value) {
    return !isNullable(value) && !validValues.includes(value) ? Option.some({
      message: VALIDATION_ERROR_MESSAGES.INVALID_OPTION
    }) : Option.none;
  };
};

exports.hasMaxLength = function (max) {
  return function (value) {
    return !isNullable(value) && value.length > max ? Option.some({
      message: VALIDATION_ERROR_MESSAGES.TOO_LONG,
      maxLength: max
    }) : Option.none;
  };
};

exports.hasMinLength = function (min) {
  return function (value) {
    return !isNullable(value) && value.length < min ? Option.some({
      message: VALIDATION_ERROR_MESSAGES.TOO_SHORT,
      minLength: min
    }) : Option.none;
  };
};

exports.isEmail = function (value) {
  return !isNullable(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? Option.some({
    message: VALIDATION_ERROR_MESSAGES.INVALID_EMAIL
  }) : Option.none;
};

exports.isPhoneNumber = function (value) {
  return !isNullable(value) && !/^\+[0-9]*$|^0[0-9]{9}$/.test(value) ? Option.some({
    message: VALIDATION_ERROR_MESSAGES.INVALID_PHONE_NUMBER
  }) : Option.none;
};

exports.isAlphaNum = function (value) {
  return !isNullable(value) && (typeof value !== 'string' || !/^[a-z0-9 ]+$/gi.test(value)) ? Option.some({
    message: VALIDATION_ERROR_MESSAGES.NOT_ALPHA_NUM
  }) : Option.none;
};

exports.isPositive = function (value) {
  return !isNullable(value) && (typeof value !== 'number' || isNaN(value) || value < 0) ? Option.some({
    message: VALIDATION_ERROR_MESSAGES.NOT_POSITIVE
  }) : Option.none;
};

exports.passesRegex = function (regex) {
  return function (value) {
    return (value === '' || !isNullable(value)) && !regex.test(value) ? Option.some({
      message: VALIDATION_ERROR_MESSAGES.INVALID_VALUE,
      regex: regex
    }) : Option.none;
  };
}; // Contraintes complexes


exports.isValidArray = function (definition) {
  return function (value) {
    if (!Array.isArray(value)) {
      return Option.some({
        message: VALIDATION_ERROR_MESSAGES.INVALID_VALUE
      });
    }

    var errorListByEntry = value.map(function (entry) {
      return isValidationConstraintList(definition) ? definition.map(function (constraint) {
        return constraint(entry);
      }) : validateInputFromValidationMap(entry, definition);
    });
    var filteredList = errorListByEntry.map(function (errorList) {
      return errorList.filter(function (error) {
        return !Option.isNone(error);
      });
    }).filter(function (errorList) {
      return errorList.length > 0;
    });
    return filteredList.length > 0 ? Option.some({
      message: VALIDATION_ERROR_MESSAGES.INVALID_VALUE
    }) : Option.none;
  };
};