"use strict"; // @ts-nocheck

/* eslint-disable */

Object.defineProperty(exports, "__esModule", {
  value: true
});

function calculatePanelCut(order) {
  //passe la plus petite dimensions en x et la plus grande en y
  var dimensions = order.dimensions.split('x').sort(function (a, b) {
    return +a.replace(',', '.') - +b.replace(',', '.');
  }); //console.log(dimensions)

  var initialX = +dimensions[0].replace(',', '.');
  var initialY = +dimensions[1].replace(',', '.');
  var x = initialX;
  var y = initialY;
  /*
  Il faut toujours découper en minimum de panneau
     A/ DPD :
   - petit coté X2 plus grand coté inférieur ou égale a 255cm
   - Départ pour la France taille maxi 200cm, pour l'Europe 175cm pour un point relais 100cm
     B/ GEODIS :
   - max 120x240cm (150x150 => 2 paneaux en 75x150)
     */

  var transporter = order.transporter;
  var dpdFrMaxY = 200;
  var dpdEuMaxY = 175;
  var geodisMaxX = 120;
  var geodisMaxY = 240;
  var parcelNumberFinal = 1;
  var parcelNumberX = 1;
  var parcelNumberY = 1;

  if (transporter !== 'geodis') {
    //DPD France ou Europe
    var dpdMaxY = !order.shippingAddress.countryCode || order.shippingAddress.countryCode === 'FR' ? dpdFrMaxY : dpdEuMaxY; //L'objectif est de renvoyer le minimum de panneau et il est possible de découper dans tous les sens
    //On va comparer 2 formules : 1 qui divise les 2 côtés et 1 qui divise tjrs le plus grand côté et on prend le meilleur
    // CALCUL FORMULE 1

    while (x * 2 + y > 255 || y > dpdMaxY) {
      if (x > dpdMaxY || x * 2 > 255 || y - x < x / 2 && y < dpdMaxY && x != y && x + y <= 255) {
        parcelNumberX++;
        x = initialX / parcelNumberX;
      } else if (y > dpdMaxY) {
        parcelNumberY++;
        y = initialY / parcelNumberY;
      } else {
        parcelNumberY++;
        y = initialY / parcelNumberY;
      } //On gère l'inversion de x et y ici


      if ((x * 2 + y <= 255 || y * 2 + x <= 255) && y < dpdMaxY && x < dpdMaxY) {
        break;
      }
    }

    parcelNumberFinal = parcelNumberX * parcelNumberY; //CALCUL FORMULE 2 : découpe tjrs le côté le plus grand : ce cas n'existe pas si x2 et y2 sont > à dpdMaxY

    if (initialX <= dpdMaxY) {
      var x2 = initialX;
      var y2 = initialY;
      var parcelNumber2 = 1;

      while (x2 * 2 + y2 > 255 || y2 > dpdMaxY) {
        parcelNumber2++;
        y2 = initialY / parcelNumber2; //On sort si y est devenu plus petit que X et que la condition est vérifiée : nécessaire car x peut être > y

        if (y2 < x2 && y2 * 2 + x2 <= 255) {
          break;
        }
      } //Comparaison des 2 formules de calcul


      if (parcelNumber2 <= parcelNumberFinal) {
        parcelNumberFinal = parcelNumber2;
        parcelNumberX = 1;
        parcelNumberY = parcelNumber2;
        x = x2;
        y = y2;
      }
    }
  } else {
    while (y > geodisMaxY || x > geodisMaxX) {
      if (x > geodisMaxX) {
        parcelNumberX++;
        x = initialX / parcelNumberX;
      } else if (y > geodisMaxY) {
        parcelNumberY++;
        y = initialY / parcelNumberY;
      }
    }

    parcelNumberFinal = parcelNumberX * parcelNumberY;
  }

  if (parcelNumberFinal > 1) {
    return "".concat(parcelNumberFinal, " (").concat(parcelNumberX, " x ").concat(parcelNumberY, " ) panneaux de ").concat(x.toFixed(2), " x ").concat(y.toFixed(2), " cm ");
  }

  return false;
}

exports.calculatePanelCut = calculatePanelCut;